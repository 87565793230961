export const Pricing = (props) => {
  return (
    <div id='pricing' className='text-center'>
      <div className='container'>
        <div className='section-title'>
          <h2>Pricing</h2>
       </div>
        <div className='pricing-text'>
        <div className='row'>
          {props.data
            ? props.data.map((d, i) => (
                <div key={`${d.category}-${i}`} className='col-xs-12 col-md-3'>
                  {' '}
                  <div className="pricingcat">
                  <h2>{d.category}</h2>
                   {d.price && d.price.indexOf("Sales") > 0? 
               <> <a
                  href='#contact'
                  className='btn btn-lg page-scroll'
                >
                  {d.price}
                </a> <br/> <h4> </h4> </> :<><h3>{d.price}</h3>
                  <br/> <h4>per store per month (minimum 10 stores yearly subscription)</h4> </>}
                  </div>
              <h3>Features</h3>
              <div className='list-style'>
                <div className='col-md-12 col-sm-6 col-xs-12'>
                  <ul>
                    {d.features
                      ? d.features.map((d, i) => (
                          <li key={`${d}-${i}`}>{d}</li>
                        ))
                      : 'loading'}
                  </ul>
                </div>
                </div>
                </div>
              ))
            : 'Loading...'}
        </div>
        </div>
      </div>
    </div>
  )
}
